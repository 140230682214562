<template>
  <div>
  <div class="grid crud-demo">
    <div class="col-12">
      <div class="card">
        <h6 class="">Administração de Leads</h6>
        <Toast/>
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button label="Novo" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
<!--              <Button label="Delete" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedLeads || !selectedLeads.length" />-->
            </div>
          </template>

          <template v-slot:end>
            <FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Importar" chooseLabel="Importar" class="mr-2 p-button-outlined" />
            <Button label="Exportar" icon="pi pi-download" class="p-button-outlined" @click="exportCSV($event)"  />
          </template>
        </Toolbar>

        <DataTable ref="dt" :value="leads" v-model:selection="selectedLeads" dataKey="id" :paginator="true" :rows="10"
                   v-model:filters="filters1" filterDisplay="menu" :loading="loading1" :filters="filters1"
                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                   currentPageReportTemplate="Showing {first} to {last} of {totalRecords} leads"
                   responsiveLayout="scroll"  :scrollable="true" scrollDirection="both"
                   class="p-datatable-leads">
          <template #header>
            <div class="flex justify-content-between flex-column sm:flex-row">
              <Button type="button" icon="pi pi-filter-slash" label="Limpar Filtros" class="p-button-outlined mb-2" @click="clearFilter1()"/>
              <span class="p-input-icon-left mb-2">
                    <i class="pi pi-search" />
                    <InputText v-model="filters1['global'].value" placeholder="Pesquisar..." style="width: 100%"/>
                  </span>
            </div>
          </template>

<!--          <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>-->
          <Column field="code" header="Código" :sortable="true" filterField="code" :showFilterMatchModes="false" :filterMenuStyle="{'width':'14rem'}" :style="{minWidth:'120px'}" >
            <template #body="slotProps">
              <span class="p-column-title">Code</span>
              {{slotProps.data.code}}
            </template>
            <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pelo código"/>
            </template>
          </Column>
          <Column field="name" header="Cliente" :sortable="true"  :style="{minWidth:'300px'}" >
            <template #body="slotProps">
              <span class="p-column-title">Name</span>
              {{slotProps.data.name}}
            </template>
            <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pelo cliente"/>
            </template>
          </Column>
<!--          <Column header="Image">-->
<!--            <template #body="slotProps">-->
<!--              <span class="p-column-title">Image</span>-->
<!--              <img :src="'demo/images/lead/' + slotProps.data.image" :alt="slotProps.data.image" class="shadow-2" width="100" />-->
<!--            </template>-->
<!--          </Column>-->
          <Column field="price" header="Valor" :sortable="true" class="text-right" :style="{minWidth:'140px', justifyContent:'flex-end'}" >
            <template #body="slotProps">
              <span class="p-column-title">Price</span>
              {{formatCurrency(slotProps.data.price)}}
            </template>
            <template #filter="{filterModel}">
              <InputNumber v-model="filterModel.value" mode="currency" currency="BRL" locale="pt-BR" class="text-right"/>
            </template>
          </Column>


          <Column field="quantity" header="Potência" :sortable="true" :style="{minWidth:'140px', justifyContent:'flex-end'}">
            <template #body="slotProps" >
              {{formatDecimal(slotProps.data.quantity)}}
            </template>

          </Column>


          <Column field="category" header="Categoria" :sortable="true" :style="{minWidth:'140px'}">
            <template #body="slotProps">
              <span class="p-column-title">Category</span>
              {{formatCurrency(slotProps.data.category)}}
            </template>

            <template #filter="{filterModel}">
              <div class="mb-3 text-bold">Agent Picker</div>
              <MultiSelect v-model="filterModel.value" :options="categorias" optionLabel="name" placeholder="Any" class="p-column-filter">
                <template #option="slotProps">
                  <div class="p-multiselect-representative-option">
<!--                    <img :alt="slotProps.option.name" :src="'demo/images/avatar/' + slotProps.option.image" width="32" style="vertical-align: middle" />-->
                    <span style="margin-left: .5em; vertical-align: middle" class="image-text">{{slotProps.option.name}}</span>
                  </div>
                </template>
              </MultiSelect>
            </template>

          </Column>
          <Column field="rating" header="Visualizações" :sortable="true" :style="{minWidth:'140px'}">
            <template #body="slotProps">
              <span class="p-column-title">Rating</span>
              <Rating :modelValue="slotProps.data.rating" :readonly="true" :cancel="false" />
            </template>
          </Column>

          <Column field="inventoryStatus" header="Status" :sortable="true" :style="{minWidth:'160px'}">
            <template #body="slotProps">
              <span class="p-column-title">Status</span>
              <span :class="'lead-badge status-' + (slotProps.data.inventoryStatus ? slotProps.data.inventoryStatus.toLowerCase() : '')">{{slotProps.data.inventoryStatus}}</span>
            </template>
          </Column>

          <Column field="inventoryStatus" header="Consultor" :sortable="true" :style="{minWidth:'120px', justifyContent:'flex-end'}">
            <template #body="slotProps">
              <span class="p-column-title">Status</span>
              <span>
              <Tag class="mr-2" :icon="'pi ' + slotProps.data.iconSC" :severity="slotProps.data.severSC" :value="slotProps.data.timeSC"></Tag>
              </span>
            </template>
          </Column>

          <Column field="inventoryStatus" header="Proposta" :sortable="true" :style="{minWidth:'120px', justifyContent:'flex-end'}">
            <template #body="slotProps">
              <span class="p-column-title">Status</span>
              <span>
              <Tag class="mr-2" :icon="'pi ' + slotProps.data.iconSPT" :severity="slotProps.data.severSPT" :value="slotProps.data.timeSPT"></Tag>
              </span>
            </template>
          </Column>

          <Column field="inventoryStatus" header="Financeiro" :sortable="true" :style="{minWidth:'120px', justifyContent:'flex-end'}">
            <template #body="slotProps">
              <span class="p-column-title">Status</span>
              <span>
              <Tag class="mr-2" :icon="'pi ' + slotProps.data.iconSFI" :severity="slotProps.data.severSFI" :value="slotProps.data.timeSFI"></Tag>
              </span>
            </template>
          </Column>

          <Column field="inventoryStatus" header="Obra" :sortable="true" :style="{minWidth:'120px', justifyContent:'flex-end'}">
            <template #body="slotProps">
              <span class="p-column-title">Status</span>
              <span>
              <Tag class="mr-2" :icon="'pi ' + slotProps.data.iconSC" :severity="slotProps.data.severSC" :value="slotProps.data.timeSC"></Tag>
              </span>
            </template>
          </Column>

          <Column field="inventoryStatus" header="Projeto" :sortable="true" :style="{minWidth:'120px', justifyContent:'flex-end'}">
            <template #body="slotProps">
              <span class="p-column-title">Status</span>
              <span>
              <Tag class="mr-2" :icon="'pi ' + slotProps.data.iconSC" :severity="slotProps.data.severSC" :value="slotProps.data.timeSC"></Tag>
              </span>
            </template>
          </Column>


          <Column header="Ações" class="text-right" :style="{width:'150px'}"  frozen alignFrozen="right">
            <template #body="slotProps">
              <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mr-2" @click="confirmDeleteLead(slotProps.data)" />
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editLead(slotProps.data)" />
              <Button icon="pi pi-sort-amount-up" class="p-button-rounded p-button-help mr-2" @click="visibleTimeline = true" />
            </template>
          </Column>
        </DataTable>




        <Dialog v-model:visible="leadDialog" :style="{width: '450px'}" header="Detalhes do Lead" :modal="true" class="p-fluid">
<!--          <img :src="'demo/images/lead/' + lead.image" :alt="lead.image" v-if="lead.image" width="150" class="mt-0 mx-auto mb-5 block shadow-2" />-->
          <div class="field">
            <label for="name">Cliente</label>
            <InputText id="name" v-model.trim="lead.name" required="true" autofocus :class="{'p-invalid': submitted && !lead.name}" />
            <small class="p-invalid" v-if="submitted && !lead.name">Cliente é obrigatório.</small>
          </div>
          <div class="field">
            <label for="description">Descrição</label>
            <Textarea id="description" v-model="lead.description" required="true" rows="3" cols="20" />
          </div>

          <div class="field">
            <label for="inventoryStatus" class="mb-3">Status</label>
            <Dropdown id="inventoryStatus" v-model="lead.inventoryStatus" :options="statuses" optionLabel="label" placeholder="Select a Status">
              <template #value="slotProps">
                <div v-if="slotProps.value && slotProps.value.value">
                  <span :class="'lead-badge status-' +slotProps.value.value">{{slotProps.value.label}}</span>
                </div>
                <div v-else-if="slotProps.value && !slotProps.value.value">
                  <span :class="'lead-badge status-' +slotProps.value.toLowerCase()">{{slotProps.value}}</span>
                </div>
                <span v-else>
									{{slotProps.placeholder}}
								</span>
              </template>
            </Dropdown>
          </div>

          <div class="field">
            <label class="mb-3">Categoria</label>
            <div class="formgrid grid">
              <div class="field-radiobutton col-6">
                <RadioButton id="category1" name="category" value="Accessories" v-model="lead.category" />
                <label for="category1">Grandes Negócios</label>
              </div>
              <div class="field-radiobutton col-6">
                <RadioButton id="category2" name="category" value="Clothing" v-model="lead.category" />
                <label for="category2">Residencial</label>
              </div>
              <div class="field-radiobutton col-6">
                <RadioButton id="category3" name="category" value="Electronics" v-model="lead.category" />
                <label for="category3">Rural</label>
              </div>
              <div class="field-radiobutton col-6">
                <RadioButton id="category4" name="category" value="Fitness" v-model="lead.category" />
                <label for="category4">Comercial</label>
              </div>
            </div>
          </div>

          <div class="formgrid grid">
            <div class="field col">
              <label for="price">Valor</label>
              <InputNumber id="price" v-model="lead.price" mode="currency" currency="BRL" locale="pt-BR" />
            </div>
            <div class="field col">
              <label for="quantity">Potência</label>
              <InputNumber id="quantity" v-model="lead.quantity" integeronly />
            </div>
          </div>
          <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
            <Button label="Salvar" icon="pi pi-check" class="p-button-text" @click="saveLead" />
          </template>
        </Dialog>

        <Dialog v-model:visible="deleteLeadDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="lead">Confirma a exclusão de <b>{{lead.name}}</b>?</span>
          </div>
          <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteLeadDialog = false"/>
            <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteLead" />
          </template>
        </Dialog>

        <Dialog v-model:visible="deleteLeadsDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="lead">Tem certeza que deseja deletar os itens selecionados?</span>
          </div>
          <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteLeadsDialog = false"/>
            <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteSelectedLeads" />
          </template>
        </Dialog>
      </div>
    </div>


  </div>
    <div class="grid p-fluid">
      <div class="col-12 lg:col-6">
        <div class="card">
          <h6>Evolução Anual</h6>
          <Chart type="bar" :data="barData" :options="barOptions"></Chart>
        </div>
      </div>
      <div class="col-12 lg:col-6">
        <div class="card flex flex-column align-items-center">
          <h6>Radar</h6>
          <Chart type="radar" :data="radarData" :options="radarOptions" style="position:relative; width: 50%"></Chart>
        </div>
      </div>
    </div>


    <div class="col-12 lg:col-12">
      <Sidebar v-model:visible="visibleTimeline" :baseZIndex="1000" position="right">
        <h3 style="font-weight: normal">Timeline</h3>
        <leads-time-line></leads-time-line>
      </Sidebar>
    </div>
  </div>

</template>

<script>
import {FilterMatchMode, FilterOperator} from 'primevue/api';
import LeadService from '@/service/LeadService';
import LeadsTimeLine from "@/components_esol/leads/LeadsTimeLine";

export default {
  components: {LeadsTimeLine},
  data() {
    return {
      visibleTimeline: false,
      loading1: true,
      filters1: null,
      leads: null,
      leadDialog: false,
      deleteLeadDialog: false,
      deleteLeadsDialog: false,
      lead: {},
      selectedLeads: null,
      filters: {},
      submitted: false,
      statuses: [
        {label: 'QUALIFICADO', value: 'qualificado'},
        {label: 'ANÁLISE', value: 'análise'},
        {label: 'DESQUALIFICADO', value: 'desqualificado'}
      ],

      categorias: [
        {name: "Rural" },
        {name: "Residencial" },
        {name: "Grandes Negócios" },
      ],


      barData: {
        labels: ['Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', ],
        datasets: [
          {
            label: 'Elaboração',
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgb(255, 99, 132)',
            data: [65, 59, 80, 81, 56, 55, 40]
          },
          {
            label: 'Convertido',
            backgroundColor: 'rgb(54, 162, 235)',
            borderColor: 'rgb(54, 162, 235)',
            data: [28, 48, 40, 19, 86, 27, 90]
          }
        ]
      },
      barOptions: {
        plugins: {
          legend: {
            labels: {
              color: '#A0A7B5'
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#A0A7B5'
            },
            grid: {
              color:  'rgba(160, 167, 181, .3)',
            }
          },
          y: {
            ticks: {
              color: '#A0A7B5'
            },
            grid: {
              color:  'rgba(160, 167, 181, .3)',
            }
          },
        }
      },


      radarData: {
        labels: ['GD1', 'GD0', 'MD1', 'MD0', 'PQ2', 'PQ1', 'PQ0'],
        datasets: [
          {
            label: 'ES',
            backgroundColor: 'rgba(54, 162, 235,0.2)',
            borderColor: 'rgba(54, 162, 235,1)',
            pointBackgroundColor: 'rgba(54, 162, 235,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(54, 162, 235,1)',
            data: [65, 59, 90, 81, 56, 55, 40]
          },
          {
            label: 'MG',
            backgroundColor: 'rgba(255, 99, 132,0.2)',
            borderColor: 'rgba(255, 99, 132,1)',
            pointBackgroundColor: 'rgba(255, 99, 132,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(255, 99, 132,1)',
            data: [28, 48, 40, 19, 96, 27, 100]
          }
        ]
      },

      radarOptions: {
        plugins: {
          legend: {
            labels: {
              color: '#A0A7B5'
            }
          }
        },
        scales: {
          r: {
            grid: {
              color: 'rgba(160, 167, 181, .3)'
            }
          }
        }
      },

    }
  },
  leadService: null,
  created() {
    this.leadService = new LeadService();
    this.initFilters();
  },
  mounted() {


    this.leadService.getLeads().then((data) => {
      console.log('leadsList mounted')
      console.log(data)
      this.loading1 = false
      this.leads = data
    })


  },
  methods: {
    formatDecimal(value) {
      if(value)
        return value.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2});
      return;
    },
    formatCurrency(value) {
      if(value)
        return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
      return;
    },
    openNew() {
      this.lead = {};
      this.submitted = false;
      this.leadDialog = true;
    },
    hideDialog() {
      this.leadDialog = false;
      this.submitted = false;
    },
    saveLead() {
      this.submitted = true;
      if (this.lead.name.trim()) {
        if (this.lead.id) {
          this.lead.inventoryStatus = this.lead.inventoryStatus.value ? this.lead.inventoryStatus.value: this.lead.inventoryStatus;
          this.leads[this.findIndexById(this.lead.id)] = this.lead;
          this.$toast.add({severity:'success', summary: 'Successo', detail: 'Lead Atualizado', life: 3000});
        }
        else {
          this.lead.id = this.createId();
          this.lead.code = this.createId();
          this.lead.image = 'lead-placeholder.svg';
          this.lead.inventoryStatus = this.lead.inventoryStatus ? this.lead.inventoryStatus.value : 'ANÁLISE';
          this.leads.push(this.lead);
          this.$toast.add({severity:'success', summary: 'Successo', detail: 'Lead Criado', life: 3000});
        }
        this.leadDialog = false;
        this.lead = {};
      }
    },
    editLead(lead) {
      this.lead = {...lead};
      this.leadDialog = true;
    },
    confirmDeleteLead(lead) {
      this.lead = lead;
      this.deleteLeadDialog = true;
    },
    deleteLead() {
      this.leads = this.leads.filter(val => val.id !== this.lead.id);
      this.deleteLeadDialog = false;
      this.lead = {};
      this.$toast.add({severity:'success', summary: 'Successo', detail: 'Lead Deletado', life: 3000});
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.leads.length; i++) {
        if (this.leads[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = '';
      var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      for ( var i = 0; i < 5; i++ ) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteLeadsDialog = true;
    },
    deleteSelectedLeads() {
      this.leads = this.leads.filter(val => !this.selectedLeads.includes(val));
      this.deleteLeadsDialog = false;
      this.selectedLeads = null;
      this.$toast.add({severity:'success', summary: 'Successo', detail: 'Leads Deletados', life: 3000});
    },
    initFilters() {
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }

      this.filters1 = {
        'code': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},


        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'country.name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'representative': {value: null, matchMode: FilterMatchMode.IN},
        'category': {value: null, matchMode: FilterMatchMode.IN},
        'date': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
        'price': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
        'status': {operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
        'activity': {value: null, matchMode: FilterMatchMode.BETWEEN},
        'verified': {value: null, matchMode: FilterMatchMode.EQUALS}
      }

    },


    clearFilter1() {
      this.initFilters();
    },
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/demo/styles/badges.scss';



</style>
